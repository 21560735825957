import {FC, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {getAuthorizationToken} from '../../../authorization-token'
import {formatDateWithTime} from '../../../datetime.formate'

interface EventDetailsState {
  eventId: number // Adjust the type of eventId if needed
}

interface TicketDetails {
  ticketCategory: string
  noOfTickets: string
  price: string
  entry: string
}

interface Staff {
  id: number
  name: string
  email: string
  country_code: string
  mobile: string
  staffId: string
}

interface EventDetails {
  id: number
  name: string
  subtitle: string
  event_datetime: string[]
  gender: string
  event_category_name: string
  location: string
  ticketType: string
  approvalRequired: string
  complimentaryDrink: number
  description: string
  general_info: string
  rulesAndRegulations: string
  gallery: string[]
  sitemap: string
  pastEventGallery: string[]
  ticketDetails: TicketDetails[]
  event_staff: Staff[]
  isFeatured: number
  city: string
  no_of_tables: number
  max_ticket: number
  site_map_enable: number
}

const EventDetails: FC = () => {
  const location = useLocation()
  const {eventId} = location.state as EventDetailsState

  const [eventDetails, setEventDetails] = useState<EventDetails | null>(null)

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get Event Details
  const fetchEventDetails = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/eventDetails/${eventId}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Event Details:", data);
        setEventDetails(data)
      })
      .catch((error) => {
        console.error('Error fetching event data:', error)
      })
  }

  // Fetch Event Details when the page loads
  useEffect(() => {
    fetchEventDetails()
  }, [eventId])

  // console.log(eventDetails?.gallery)
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Event Details</h3>
        </div>

        <Link
          to={'/events/edit-event'}
          state={{eventId: eventId}}
          className='btn btn-primary align-self-center'
        >
          Edit
        </Link>
      </div>

      {eventDetails && (
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Event Date</label>

            <div className='col-lg-8'>
              {eventDetails.event_datetime.map((dateString, index) => {
                const dates = dateString.split('|')
                if (dates.length >= 2) {
                  const date1 = dates[0].trim() // First date part
                  const date2 = dates[1].trim() // Second date part
                  return (
                    <span key={index} className='fw-bolder fs-6 text-dark'>
                      {formatDateWithTime(date1)} - {formatDateWithTime(date2)}
                      {index !== eventDetails.event_datetime.length - 1 && <br />}
                    </span>
                  )
                }
                return null // Handle the case where there are not enough date parts
              })}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Event Name</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{eventDetails.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Is Featured</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{eventDetails.isFeatured === 1 ? 'Yes' : 'No'}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Location
              {/* <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Phone number must be active'
                            ></i> */}
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{eventDetails.location}</span>

              {/* <span className='badge badge-success'>Verified</span> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>City</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{eventDetails.city}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Admin Approval</label>

            <div className='col-lg-8'>
              <a className='fw-bold fs-6 text-dark text-hover-primary'>
                {eventDetails.approvalRequired === '1' ? 'Yes' : 'No'}
              </a>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Gender
              {/* <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Country of origination'
                            ></i> */}
            </label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {eventDetails.gender === 'M'
                  ? 'Male'
                  : eventDetails.gender === 'F'
                  ? 'Female'
                  : 'Any'}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Category</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{eventDetails.event_category_name}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Ticket Buy Type</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{eventDetails.ticketType}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Maximum Ticket</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-dark'>{eventDetails.max_ticket?eventDetails.max_ticket:0}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Complimentary Drink</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>
                {eventDetails.complimentaryDrink === 1 ? 'Yes' : 'No'}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>No of Tables</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-dark'>{eventDetails.no_of_tables}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Description</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-dark'>{eventDetails.description}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>General Information</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-dark'>{eventDetails.general_info}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Rules and Regulations</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-dark'>{eventDetails.rulesAndRegulations}</span>
            </div>
          </div>

          <div className='row mb-8'>
            <label className='col-lg-4 fw-bold text-muted' style={{marginTop: '15px'}}>
              Assign Staff
            </label>

            <div className='col-lg-8'>
              <div className='fw-bold'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-100px'>StaffId</th>
                        <th className='min-w-100px'>Staff Name</th>
                        <th className='min-w-100px'>Email</th>
                        <th className='min-w-100px'>Mobile Number</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {eventDetails.event_staff.map((staff, index) => (
                        <tr key={index}>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {staff.staffId}
                            </a>
                          </td>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {staff.name}
                            </a>
                          </td>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {staff.email}
                            </a>
                          </td>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {staff.country_code} {staff.mobile}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>

          {/* <div className='row mb-6'>
                        <label className='col-lg-4 fw-bold text-muted'>Gallery</label>
                        <div className='col-lg-8'>
                            <div
                                className='image-input image-input-outline'
                                data-kt-image-input='true'
                            // style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                            >
                                <div
                                    className='image-input-wrapper w-125px h-125px'
                                // style={{ backgroundImage: `url(${toAbsoluteUrl(data.avatar)})` }}
                                ></div>
                            </div>
                        </div>
                    </div> */}

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Gallery</label>
            <div className='col-lg-8'>
              {/* {eventDetails.gallery.map((image, index) => (
                                <div className='image-input image-input-outline' data-kt-image-input='true' key={index}>
                                    <div
                                        className='image-input-wrapper w-125px h-125px'
                                        style={{ backgroundImage: `url("${image}")` }} // Replace image with the actual URL
                                    ></div>
                                </div>
                            ))} */}
              {eventDetails.gallery.map((image, index) => {
                const isVideo = ['.mp4', '.webm', '.ogg'].some((extension) =>
                  image.endsWith(extension)
                )
                return (
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    key={index}
                  >
                    {isVideo ? (
                      <video width='125' height='125' controls>
                        <source src={image} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url("${image}")`}}
                      ></div>
                    )}
                    {/* <div
                                            className='image-input-wrapper w-125px h-125px'
                                            style={{ backgroundImage: `url("${image}")` }} // Replace image with the actual URL
                                        ></div> */}
                  </div>
                )
              })}
            </div>
          </div>

     {eventDetails.site_map_enable === 1 && eventDetails.sitemap && (
  <div className='row mb-6'>
    <label className='col-lg-4 fw-bold text-muted'>Floor Plan Image</label>
    <div className='col-lg-8'>
      <div className='image-input image-input-outline' data-kt-image-input='true'>
        {['.mp4', '.webm', '.ogg'].some((extension) =>
          eventDetails.sitemap.endsWith(extension)
        ) ? (
          <video width='125' height='125' controls>
            <source src={eventDetails.sitemap} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div
            className='image-input-wrapper w-125px h-125px'
            style={{ backgroundImage: `url("${eventDetails.sitemap}")` }}
          ></div>
        )}
      </div>
    </div>
  </div>
)}

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Floor Plan Enable</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{eventDetails.site_map_enable === 1 ? 'Yes' : 'No'}</span>
            </div>
          </div>


          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-muted'>Past Event Image Gallery</label>
            <div className='col-lg-8'>
              {eventDetails.pastEventGallery.map((image, index) => {
                const isVideo = ['.mp4', '.webm', '.ogg'].some((extension) =>
                  image.endsWith(extension)
                )
                return (
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    key={index}
                  >
                    {isVideo ? (
                      <video width='125' height='125' controls>
                        <source src={image} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url("${image}")`}}
                      ></div>
                    )}
                    {/* <div
                                            className='image-input-wrapper w-125px h-125px'
                                            style={{ backgroundImage: `url("${image}")` }} // Replace image with the actual URL
                                        ></div> */}
                  </div>
                )
              })}
            </div>
          </div>

          <div className='row mb-8'>
            <label className='col-lg-4 fw-bold text-muted' style={{marginTop: '15px'}}>
              Ticket Details
            </label>

            <div className='col-lg-8'>
              <div className='fw-bold'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-100px'>Ticket Category</th>
                        <th className='min-w-100px'>No of Tickets</th>
                        <th className='min-w-100px'>Price</th>
                        <th className='min-w-100px'>Entry Gate</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {eventDetails.ticketDetails.map((ticket, index) => (
                        <tr key={index}>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {ticket.ticketCategory === 'Free' ? 'Guest List' : ticket.ticketCategory}
                            </a>
                          </td>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {ticket.noOfTickets}
                            </a>
                          </td>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {ticket.price}
                            </a>
                          </td>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {ticket.entry}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EventDetails

// < div className = 'notice d-flex bg-light-warning rounded border-warning border border-dashed p-6' >
//     {/* <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' /> */ }
//     < div className = 'd-flex flex-stack flex-grow-1' >
//         <div className='fw-bold'>
//             <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
//             <div className='fs-6 text-gray-600'>
//                 Your payment was declined. To start using tools, please
//                 <Link className='fw-bolder' to='/crafted/account/settings'>
//                     {' '}
//                     Add Payment Method
//                 </Link>
//                 .
//             </div>
//         </div>
//                     </div >
//                 </div >
