/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'

// type Props = {
//   className: string
// }

const SettingsWrapper: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className='card-xxl-stretch mb-5 mb-xl-10'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-1 mb-1'>Settings</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>More than 400 new products</span> */}
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-10'>
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='border-0'>
                  <th className='p-0 '></th>
                  <th className='p-0 '></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/manage-category')}>
                  <td>
                    <Link to='/settings/manage-category' className='text-dark  text-hover-primary mb-1 fs-6'>
                      Manage Category
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link
                      to='/settings/manage-category'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/manage-ticket-category')}>
                  <td>
                    <Link to='/settings/manage-category' className='text-dark  text-hover-primary mb-1 fs-6'>
                      Manage Ticket Category
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link
                      to='/settings/manage-category'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/manage-approval')}>
                  <td>
                    <Link to='/settings/manage-approval' className='text-dark  text-hover-primary mb-1 fs-6'>
                      Manage Approval
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link
                      to='/settings/manage-approval'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/discount-code')}>
                  <td>
                    <Link to='/settings/discount-code' className='text-dark  text-hover-primary mb-1 fs-6'>
                      Discount Code
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link
                      to='/settings/discount-code'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-5 mb-1'>Manage CMS</span>
                {/* <span className='text-muted mt-1 fw-semibold fs-7'>More than 400 new products</span> */}
              </h3>
            </div>
            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='border-0'>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-50px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/aboutUs')}>
                  <td>
                    <Link to='/settings/aboutUs' className='text-dark  text-hover-primary mb-1 fs-6'>
                      About Us
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link to='/settings/aboutUs' className='text-dark  text-hover-primary mb-1 fs-6'>
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/support')}>
                  <td>
                    <Link to='/settings/support' className='text-dark  text-hover-primary mb-1 fs-6'>
                      Support
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link to='/settings/support' className='text-dark  text-hover-primary mb-1 fs-6'>
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/privacy-policy')}>
                  <td>
                    <Link to='/settings/privacy-policy' className='text-dark  text-hover-primary mb-1 fs-6'>
                      Privacy Policy
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link to='/settings/privacy-policy' className='text-dark  text-hover-primary mb-1 fs-6'>
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/terms-and-conditions')}>
                  <td>
                    <Link to='/settings/terms-and-conditions' className='text-dark  text-hover-primary mb-1 fs-6'>
                      Terms and Conditions
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link to='/settings/terms-and-conditions' className='text-dark  text-hover-primary mb-1 fs-6'>
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
                <tr className='bg-hover-white' onClick={() => navigate('/settings/fAQs')}>
                  <td>
                    <Link to='/settings/fAQs' className='text-dark  text-hover-primary mb-1 fs-6'>
                      FAQs
                    </Link>
                  </td>
                  <td className='text-end'>
                    <Link to='/settings/fAQs' className='text-dark  text-hover-primary mb-1 fs-6'>
                      <KTIcon iconName='arrow-right' className='fs-2' />
                    </Link>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default SettingsWrapper
