import {useEffect, useMemo, useState} from 'react'
import {
  KTCard,
  KTCardBody,
  KTIcon,
  initialQueryState,
  useDebounce,
} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {formatDate, formatDateWithTime} from '../../../datetime.formate'
import {getAuthorizationToken} from '../../../authorization-token'
import PaginationComponent from '../../../pagination'
import PlaceHolderComponent from '../../../skeletol-view'
import {useLocation, useNavigate} from 'react-router-dom'

interface AllCategory {
  id: number
  event_category: string
  status: string
}

const RegistrationRequest = () => {
  interface User {
    request: String
    artist: {
      art: string[]
      level: string
      status: string
      request_date: string
      portfolio_link: string
    }
    collaborator: {
      category: string[]
      type: string
      status: string
      request_date: string
      portfolio_link: string
    }

    id: number
    full_name: string
    email: string
    mobile_number: string
    gender_id: number
    gender: string
    dob: string
    age: number
    event_categories: string
    date_created: string
    status: string
    reference_name:string
  }
  const location = useLocation()
  const navigate = useNavigate()
  const [userData, setUserData] = useState<User[]>([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchDate, setSearchDate] = useState<string>('')
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedGenderFilter, setSelectedGenderFilter] = useState<string>('')
  const [selectedSortBY, setSelectedSortBY] = useState<string>('')
  const [allCategory, setAllCategory] = useState<AllCategory[]>([])

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()
  const isapproved = 'REQUEST'

  // Get ALL User List
  const fetchUserData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/allUsersRegistrationRequest/${isapproved}`, {
      headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
    })
      .then((result) => {
        result.json().then((resp) => {
          setLoading(false)
          setUserData(resp)
          console.log(resp)
        })
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })
  }

  // For Get ALL User List When page is load
  useEffect(() => {
    fetchUserData()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const usersPerPage = 10

  const filteredData = userData
    .filter(
      (user) =>
        ((user.full_name &&
          user.full_name.toLowerCase().includes(searchTerm?.toLowerCase() ?? '')) ||
          (user.email && user.email.toLowerCase().includes(searchTerm?.toLowerCase() ?? '')) ||
          (user.mobile_number &&
            user.mobile_number.toLowerCase().includes(searchTerm?.toLowerCase() ?? ''))) &&
        (!selectedGenderFilter ||
          selectedGenderFilter === 'Any' || 
          user.gender === selectedGenderFilter) &&
        (!selectedCategory ||
          selectedCategory === 'A' ||
          (user.event_categories && user.event_categories.split(',').includes(selectedCategory))) &&
        (!searchDate ||
          (user.date_created &&
            new Date(user.date_created)?.toISOString()?.substr(0, 10) ===
              new Date(searchDate)?.toISOString()?.substr(0, 10))) &&
        (!selectedStatus ||
          (user.status === 'Active' && selectedStatus === 'Active') ||
          (user.status === 'Inactive' && selectedStatus === 'Inactive'))
    )
    .sort((a, b) => {
      const dateA: Date = new Date(a.date_created)
      const dateB: Date = new Date(b.date_created)

      if (selectedSortBY === 'dateNewest') {
        return dateB.getTime() - dateA.getTime()
      }
      if (selectedSortBY === 'dateOldest') {
        return dateA.getTime() - dateB.getTime()
      }

      return 0
    })

  // Reset current page to 1 when data is filtered
  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerm, selectedGenderFilter, selectedCategory, searchDate, selectedStatus])

  // Get the users to display on the current page
  const indexOfLastData = currentPage * usersPerPage
  const indexOfFirstData = indexOfLastData - usersPerPage
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData)



  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / usersPerPage)

  // Array for page numbers
  const pageNumbers = Array.from({length: totalPages}, (_, index) => index + 1)

  return (
    <div className='mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div
          className='card-title m-0 d-flex align-items-center'
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-2 mb-1'>Registration Request</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
          </h3>
          <div className='card-toolbar '>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-300px ps-14'
                placeholder='Search by user name, mobile '
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='date'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-180px ps-14'
                placeholder='Date'
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
              />
              <KTIcon iconName='calendar' className='fs-1 position-absolute ms-6' />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedGenderFilter || ''}
                onChange={(e) => {
                  setSelectedGenderFilter(e.target.value)
                }}
              >
                {/*      */}
                <option value='Any'>Gender</option>
                <option value='M'>Male</option>
                <option value='F'>Female</option>
                <option value='A'>Any</option>
              </select>
            </div>
            {/* end::Search */}
          </div>

          <div className='card-toolbar'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <select
                className='form-select form-select-solid form-select-lg-2'
                value={selectedSortBY || ''}
                onChange={(e) => {
                  setSelectedSortBY(e.target.value)
                }}
              >
                {/*      */}
                <option value=''>Sort By</option>
                <option value='dateNewest'>Registered Date Newest First</option>
                <option value='dateOldest'>Registered Date Oldest First</option>
              </select>
            </div>
            {/* end::Search */}
          </div>
        
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 my-6'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Registered Date</th>
                <th className='min-w-100px'>User Name</th>
                <th className='min-w-100px'>Mobile Number</th>
                <th className='min-w-100px'>Gender</th>
                <th className='min-w-100px'>Age</th>
                <th className='min-w-100px'>Reffered By</th>
                <th className='min-w-100px'>Email</th>
                <th className='min-w-125px text-center rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {loading && <PlaceHolderComponent tableHeaders={8} />}
              {currentData.map((user) => (
                <tr>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatDate(user.date_created)}

                      {/* {user.request === 'Artist'
                        ? formatDate(user.artist.request_date)
                        : formatDate(user.collaborator.request_date)} */}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.full_name?user.full_name:'-'}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.mobile_number?user.mobile_number:'-'}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.gender?user.gender:'-'}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user.age?user.age:'-'}
                    </a>
                  </td>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {
                        <a
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          // title={user.event_categories.length > 20 ? user.event_categories : ''}
                        >
                          {user.reference_name?user.reference_name:'-'}
                          {/* {user.event_categories.length > 20
                          ? user.event_categories.slice(0, 20) + '...'
                          : user.event_categories} */}
                        </a>
                      }
                    </a>
                  </td>
                  <td>
                    <span>
                        {user.email?user.email:'-'}

                    </span>
                  </td>
                  <td
                    className='text-center'
                    onClick={() =>
                      navigate('/registration-request/details', {
                        state: {id: user.id, userName: user.full_name, request: user.request},
                      })
                    }
                  >
                    <Link
                      to='/registration-request/details'
                      className='btn btn-bg-light btn-color btn-active-color-primary btn-sm px-4 me-2'
                    >
                      View
                    </Link>
                  </td>
                </tr>
               ))} 
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={filteredData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default RegistrationRequest
