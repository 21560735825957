import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import { useListView } from '../../modules/apps/user-management/users-list/core/ListViewProvider'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { getAuthorizationToken } from '../../../authorization-token'
import PaginationComponent from '../../../pagination'

const categorySchema = Yup.object().shape({
  event_category: Yup.string().required('Name is required'),
})

const ManageApproval: React.FC = () => {
  interface Category {
    id: number
    event_category: string
    is_approval_required: boolean
    task_name: string
  }

  const [categoryData, setCategoryData] = useState<Category[]>([])
  const [categoryError, setCategoryError] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()

  // Get all Event-Category List
  const fetchCategoryData = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllApprovalRequest`, {
      headers: { Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}` },
    })
      .then((result) => {
        result.json().then((resp) => {
          console.log("Result", resp)
          setCategoryData(resp.data)
        })
      })
      .catch((error) => {
        console.error('Error fetching staff data:', error)
      })
  }

  // For Get ALL User List When page is load
  useEffect(() => {
    fetchCategoryData()
  }, [])



  // Update Category Status
  const updateCategorystatus = (categoryId: number, newStatus: boolean, task_name:string) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/updateApprovalRequest`, {
      method: 'PUT',
      headers: {
        Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: categoryId, task_name:task_name, is_approval_required:newStatus }),
    })
      .then((result) => {
        if (result.status === 200) {
          fetchCategoryData()
        }
      })
      .catch((err) => {
        console.log('Error', err)
      })
  }





 



 








  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10 // Number of items to display per page





  const indexOfLastData = currentPage * itemsPerPage
  const indexOfFirstData = indexOfLastData - itemsPerPage
  console.log(indexOfLastData)
  console.log(indexOfFirstData)
  console.log(categoryData)
  const currentData = categoryData.slice(indexOfFirstData, indexOfLastData)


  // Total number of pages
  const totalPages = Math.ceil(categoryData.length / itemsPerPage)
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1)

  return (
    <div className='mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div
        className='card-title m-0 d-flex align-items-center'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3 className='card-title align-items-start flex-column'>
          <Link to='/settings' className='btn btn-sm btn-icon btn-active-color-primary text-end '>
            <KTIcon iconName='arrow-left' className='fs-2' />
          </Link>
          <span className='card-label fw-bold fs-2 mb-1'>Manage Approval</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new members</span> */}
        </h3>
        {/* <div className='card-toolbar '>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-362px ps-14'
              placeholder='Search by category Name '
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div> */}
    
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 my-6'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-100px'>Approval Name</th>
                <th className='min-w-150px text-center rounded-end'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {currentData.map((category) => (
                <tr key={category.id}>
                  <td>
                    <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {category.task_name}
                    </a>
                  </td>
                  <td className='text-center'>
                    <div
                      className='dropdown'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <a
                        className='btn btn-light btn-active-light-primary btn-sm'
                        role='button'
                        id={`dropdownMenu${category.id}`}
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                            {category.is_approval_required ? 'Active' : 'Inactive'}
                           <KTIcon iconName='down' className='fs-5 m-0' />
                      </a>
                      <ul
                        className='dropdown-menu'
                        aria-labelledby={`dropdownMenu${category.id}`}
                      >
                        <li>
                          <a
                            className='dropdown-item'
                            onClick={() => {
                              updateCategorystatus(
                                category.id,
                                category.is_approval_required ? false: true,
                                category.task_name
                              );
                            }}
                          >
                            {category.is_approval_required ? 'Inactive' : 'Active'}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
  
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {/* Pagination */}
        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            // pageNumbers={pageNumbers}
            currentData={currentData}
            filteredData={categoryData}
            indexOfFirstData={indexOfFirstData}
            indexOfLastData={indexOfLastData}
          />
        )}
      </div>
      {/* begin::Body */}

    </div>
  )
}

export default ManageApproval;