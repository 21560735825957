import {
    KTCard,
    KTCardBody,
    KTIcon,
    initialQueryState,
    useDebounce,
  } from '../../../_metronic/helpers'
  import {Link} from 'react-router-dom'
  import Container from 'react-bootstrap/Container'
  import Navbar from 'react-bootstrap/Navbar'
  import Button from 'react-bootstrap/Button'
  import {useState, useEffect} from 'react'
  import * as Yup from 'yup'
  import {Formik, Form, Field, ErrorMessage} from 'formik'
  import {useLocation} from 'react-router-dom'
  import {getAuthorizationToken} from '../../../authorization-token'
  import {useNavigate} from 'react-router-dom'
  import {useFormik} from 'formik'
  
  interface RequestDetailType {
    art: string[]
    level: string
  
    category: string[]
    type: string
  
    status: string
    request_date: string
    portfolio_link: string
    portfolio_pdf: string
    full_name:string
    mobile_number:number
    age:number
    nationality:string
    gender:string
    interest:string
    instagram_profile:string
    event_categories:string
    reference_name:string
    reference_email:string
    reference_mobile_number:number
    reference_relation:string
    image:string
  }
  
  interface State {
    id: number
    userName: string
    request: string
  }
  
  const rejectSchema = Yup.object().shape({
    reason: Yup.string().required('Reason is Required'),
  })
  
  const RegistrationRequestDetails = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as State
    const userId = state.id
    console.log('request Details userId', userId)
    const userName = state.userName
    const request = state.request
    const [ApproveModal, setApproveModal] = useState(false)
    const [RejectModal, setRejectModal] = useState(false)
    const [dataLoading, setDataLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [Approveloading, ApprovesetLoading] = useState(false)
    const [userData, setUserData] = useState<RequestDetailType>()
  
  
    const closeModal = () => {
      setApproveModal(false)
      setRejectModal(false)
    }
    const openRejectModal = () => {
      setRejectModal(true)
    }
  
    const REACT_APP_AUTHORIZATION_TOKEN = getAuthorizationToken()
  
    // Get ALL User List
    const fetchUserData = () => {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/userDetails/${userId}`,
        {
          headers: {Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`},
        }
      )
        .then((result) => {
          result.json().then((resp) => {
            setDataLoading(false)
            setUserData(resp)
            console.log(resp)
          })
        })
        .catch((error) => {
          console.error('Error fetching user data:', error)
        })
    }
  
    // For Get ALL User List When page is load
    useEffect(() => {
      fetchUserData()
    }, [])
  
    const approveRequest = () => {
      ApprovesetLoading(true)
      fetch(`${process.env.REACT_APP_API_BASE_URL}/users/isApproveStatus`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify({id: userId, is_approve: 'APPROVE'}),
      }).then((result) => {
        if (result.status === 200) {
          ApprovesetLoading(false)
          navigate('/registration-request')
        } else if (result.status === 400) {
          ApprovesetLoading(false)
        } else {
          ApprovesetLoading(false)
          console.log('Error', result)
        }
      })
    }
  
    const formik = useFormik({
      initialValues: {
        reason: '',
      },
      validationSchema: rejectSchema,
      onSubmit: (values) => {
        rejectRequest(values.reason)
      },
    })
  
    function rejectRequest(reason: string) {
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_BASE_URL}/users/isApproveStatus`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `${REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify({
          id: userId,
          is_approve: 'REJECT',
          reason: reason,
        }),
      }).then((result) => {
        if (result.status === 200) {
          setLoading(false)
          navigate('/registration-request')
        } else if (result.status === 400) {
          setLoading(false)
        } else {
          setLoading(false)
          console.log('Error', result)
        }
      })
    }
  
    return (
      <div className='my-3'>
        <div className='d-grid gap-2'>
          <Navbar
            className=''
            style={{backgroundColor: 'white', outline: '1px solid #E5E5E6', borderRadius: '6px'}}
          >
            <Container>
              <Link to='/registration-request' className='btn btn-sm btn-icon btn-active-color-primary '>
                <KTIcon iconName='arrow-left' />
              </Link>
              <Navbar.Brand>
                <h2>
                  <b>Request Details</b>
                </h2>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className='justify-content-end mb-2'>
                <Navbar.Text>
                  {!dataLoading && (
                    <Button className='mx-2' size='sm' variant='primary'onClick={approveRequest} disabled={Approveloading}>
                  {Approveloading?"Approving":"Approve"}
                    </Button>
                  )} 
                </Navbar.Text>
                <Navbar.Text>
                  {!dataLoading && ( 
                    <Button size='sm' variant='danger' onClick={openRejectModal}>
                      Reject
                    </Button>
                   )} 
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>
    
          <>
            {RejectModal && (
              <div
                className='modal fade show d-block'
                id='kt_modal_reject'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
              >
                <div className='modal-dialog modal-dialog-centered mw-450px'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h2 className='fw-bolder' style={{textAlign: 'center'}}>
                        Are you sure want to Reject?
                      </h2>
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-danger'
                        onClick={closeModal}
                        style={{cursor: 'pointer'}}
                      >
                        <KTIcon iconName='cross' className='fs-1' />
                      </div>
                    </div>
                    <div className='scroll-y mx-5 mx-xl-15 my-7'>
                      <form onSubmit={formik.handleSubmit}>
                        <input
                          type='textarea'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Reason'
                          {...formik.getFieldProps('reason')}
                          name='reason'
                        />
                        {formik.touched.reason && formik.errors.reason && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.reason}</div>
                          </div>
                        )}
                        <div className='text-center pt-15'>
                          <button type='reset' onClick={closeModal} className='btn btn-light me-3'>
                            Cancel
                          </button>
                          <button type='submit' className='btn btn-danger' disabled={loading}>
                            {loading ? 'Rejecting' : 'Reject'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {RejectModal && <div className='modal-backdrop fade show'></div>}
          </>
        </div>
  
        <>
        <div className='row mb-8 my-7 mx-7'>
  <label className='col-lg-4 fw-bold text-muted'>Image</label>
  <div className='col-lg-8 fv-row'>
    <div style={{ width: '100px', height: '100px', overflow: 'hidden', borderRadius: '50%' }}>
      <img 
        src={userData?.image} 
        alt='User-Profile' 
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </div>
  </div>
</div>

          <div className='row mb-8 my-7 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>User Name</label>
  
            <div className='col-lg-8 fv-row'>
          
                <span className='fw-bold fs-6'>{userData?.full_name}</span>
                {/* <KTIcon iconName='social-media' className='ms-2' /> */}
              
            </div>
          </div>
          <div className='row mb-8 my-7 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Mobile Number</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                {userData?.mobile_number}
              </span>
            </div>
          </div>
          <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Gender</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                { userData?.gender}
              </span>
            </div>
          </div>
          <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Age</label>
  
            <div className='col-lg-8 fv-row'>
      
                { userData?.age}
            </div>
          </div>
          <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Nationality</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                { userData?.nationality}
              </span>
            </div>
          </div>
          <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Interest</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
              {userData?.event_categories}
              </span>
            </div>
          </div>
          <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Instagram</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                { userData?.instagram_profile}
              </span>
            </div>
          </div>
          <br />
          <h4 className='mx-5'>
                  <b>Reference Details</b>
                </h4>
                <br />
                <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Reference Name</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                { userData?.reference_name}
              </span>
            </div>
          </div> <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Reference Email</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                { userData?.reference_email}
              </span>
            </div>
          </div> <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>Reference Mobile Number</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                { userData?.reference_mobile_number}
              </span>
            </div>
          </div> <div className='row mb-7 my-5 mx-7'>
            <label className='col-lg-4 fw-bold text-muted'>How do you know this person?</label>
  
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                { userData?.reference_relation}
              </span>
            </div>
          </div>
        </>
      </div>
    )
  }
  export default RegistrationRequestDetails
  