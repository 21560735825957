import { S3Client, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3'
import fs from 'node:fs/promises'
import path from 'path'

export class ImageHandler {
    private bucketName = process.env.REACT_APP_MY_AWS_BUCKET_NAME
    private awsRegion: string

    s3: S3Client

    pathUserProfile = 'user/profile/'

    constructor() {
        // console.log(process.env)
        this.s3 = new S3Client({
            region: process.env.REACT_APP_MY_AWS_REGION,
            credentials: {
                secretAccessKey: process.env.REACT_APP_MY_AWS_SECRET_ACCESS_KEY!,
                accessKeyId: process.env.REACT_APP_MY_AWS_ACCESS_KEY_ID!,
            },
        })
        this.awsRegion = 'ap-south-1'
    }

    /**
     * Uploads a single file to s3 bucket. The name of the image will remain the same as the provided image path.
     *
     * @param image full path to image file. eg upload/image.jpg
     * @param savePath folder in which the image will be stored in s3. eg folder/sub-folder/
     * @returns
     */
    async upload(image: Blob, savePath: string) {
        // get image buffer from filePath
        // const fileContent = await fs.readFile(image).catch((err) => err as Error)
        // if (fileContent instanceof Error) {
        //     return fileContent
        // }

        // const filename = path.basename(image)

        const fileNameFinal = savePath + this.generateFileName(image.name)

        // setup required command for upload
        const command = new PutObjectCommand({
            Bucket: this.bucketName,
            Key: fileNameFinal,
            Body: image,
        })

        // upload image
        const result = await this.s3.send(command).catch((err) => err as Error)
        // console.log(result)

        if (result instanceof Error) {
            console.error(result)
            return result
        }

        // delete local file
        // await fs.rm(image)

        return this.generateImageLink(fileNameFinal)
    }

    /**
     * deletes a single file on s3.
     *
     * @param filePath
     * This can be the full path on s3 (eg. folder/sub-folder/file.jpg)
     * or it can be the full path of the s3 link (eg. https://bucket.s3.region.amazonaws.com/folder/sub-folder/file.jpg)
     * @returns
     */
    async delete(filePath: string) {
        if (filePath.includes('amazonaws.com')) {
            filePath = filePath.split('amazonaws.com/')[1]
        }

        // setup required commands for deletion
        const command = new DeleteObjectCommand({
            Bucket: this.bucketName,
            Key: filePath,
        })

        const result = await this.s3.send(command).catch((err) => err as Error)

        if (result instanceof Error) {
            return result
        }

        return true
    }

    private generateImageLink(imagePath: string) {
        return `https://${this.bucketName}.s3.${this.awsRegion}.amazonaws.com/${imagePath}`
    }

    private generateFileName(filename: string) {
        const timestamp = Date.now().toString().slice(-4)

        const newFilename = `${timestamp}_${filename.replace(' ', '_')}`

        return newFilename
    }
}
